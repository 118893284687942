import './src/scss/index.scss';


// https://support.cookieinformation.com/en/articles/5411279-google-consent-mode-implementation
const initGTM = () => {
	window.dataLayer = window.dataLayer || [];
	
	function gtag() {
		// eslint-disable-next-line no-undef
		dataLayer.push(arguments);
	}
	gtag('consent', 'default', {
		ad_storage: 'denied',
		analytics_storage: 'denied',
		wait_for_update: 500,
	});
	gtag('set', 'ads_data_redaction', true);
	gtag('js', new Date());
	gtag('config', process.env.GA_TRACKING_ID);
};

export const onClientEntry = () => {
	initGTM();
};